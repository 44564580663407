.message {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: yellow;
    font-size: 20px;
    animation-name: blink;
    animation-delay: 2s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.message.result {
    font-weight: bold;
    animation-name: result;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-duration: 1s;
}

@keyframes blink {
    0% {opacity: 1}
    50% {opacity: 0}
    100% {opacity: 1}
}

@keyframes result {
    0% {
        /* visibility: hidden; */
        scale: 0;
    }
    
    15% {
        transform: rotateZ(90deg);
    }

    100% {
        scale: 1.1;
    }
}