.greeting {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    background-color: #111;
    color: #ddd;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    font-family: 'Jura', sans-serif;
}

h1 {
    font-size: 60px;
    font-weight: 700;
    color: #afa;
}

.playBtn {
    padding: 10px;
    font-size: 48px;
    font-weight: bold;
    outline: none;
    border: #afa;
    border-style: solid;
    border-radius: 50%;
    background-color: #5cb85c;
    height: 150px;
    width: 150px;
    color: white;
    cursor: pointer;
    box-shadow: 1px 1px 15px 1px #ddd;
}

footer {
    padding: 20px;
}