:root {
    --endDistY: -820px;
}

.ball {
    background-image: url("./ball.webp");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 72px;
    width: 72px;
    border-radius: 50%;
    margin-bottom: 100px;
    position: absolute;
    top: 122px;
    z-index: 6;
    /* position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%); */
}

.ball.left {
    animation-name: shootLeft;
    animation-delay: 0.1s;
    animation-duration: .9s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
}
.ball.right {
    animation-name: shootRight;
    animation-delay: 0.1s;
    animation-duration: .9s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
}
.ball.up {
    animation-name: shootUp;
    animation-delay: 0.1s;
    animation-duration: .9s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
}

@keyframes shootLeft {
    0% {transform: scale(1) translateX(0) translateY(-40px) rotateZ(-360deg);}
    100% {transform: scale(.52) translateX(-240px) translateY(var(--endDistY)) rotateZ(1080deg);}
}

@keyframes shootRight {
    0% {transform: scale(1) translateX(0) translateY(-40px) rotateZ(360deg)}
    100% {transform: scale(.52) translateX(240px) translateY(var(--endDistY)) rotateZ(-1080deg);}
}

@keyframes shootUp {
    0% {transform: scale(1) translateX(0) translateY(-40px) rotateZ(360deg)}
    100% {transform: scale(.52) translateX(0) translateY(var(--endDistY)) rotateZ(-1080deg);}
}
