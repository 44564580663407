.scoreboard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background-color: #000;
    color: #eee;
}

table,
td,
th {
    border-collapse: collapse;
    border: 1px solid #232;
    text-align: center;
}

td,
th {
    min-width: 32px;
    padding: 2px;
}

th {
    min-width: 68px;
    color: #afa;
    height: 32px;
    font-family: "Jura", sans-serif;
    font-weight: 700;
    font-size: 18px;
}

td {
    font-size: 17px;
    color: #f2fdb7;
}