/* .goal-container {
    display: flex;
    justify-content: center;
} */

.goal {
    margin-top: 25px;
    height: 100px;
    width: 300px;
    background-color: #bbc;;
    border-top: 5px #fff solid;
    border-left: 5px #fff solid;
    border-right: 5px #fff solid;
    z-index: 2;
}