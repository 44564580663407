.goalkeeper {
    margin-top: -80px;
    z-index: 4;
    /* background-color: palevioletred; */
}

.goalkeeper.left {
    animation: slideLeft .8s ease-out;
    animation-delay: .1s;
}
.goalkeeper.right {
    animation: slideRight .8s ease-out;
    animation-delay: .1s;
}
.goalkeeper.up {
    animation: jumpUp .8s ease-out;
    animation-delay: .1s;
}

.gk-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*h: 36, w: 32*/
.gk-head-1 {
    height: 45px;
    width: 31px;
    background-image: url("./alfGKc.jpg");
    /* background-image: url("./kkGK.png"); */
    background-size: 35px 48px;
    background-color: burlywood;
    background-position: center center;
    border-radius: 50%;
}

.gk-head-2 {
    height: 45px;
    width: 30px;
    /* background-image: url("./lioGK.jpg"); */
    background-image: url("./crocGK.jpg");
    background-size: 35px 48px;
    background-color: burlywood;
    background-position: center center;
    border-radius: 50%;

}

.gk-upper-body, .gk-legs {
    display: flex;
    justify-content: center;
}

.gk-arm-l, 
.gk-arm-r {
    margin-left: 2px;
    margin-right: 2px;
    height: 8px;
    width: 44px;
    background-color: cornflowerblue;
    border-radius: 3px;
}

.gk-arm-l {
    transform: translateX(12px) translateY(-15px) rotateZ(45deg);
}

.gk-arm-r {
    transform: translateX(-12px) translateY(-15px) rotateZ(-45deg);
}

.gk-torso {
    width: 32px;
    height: 48px;
    background-color: #0ef;
    border-radius: 5px;
}

.gk-legs-l, .gk-legs-r {
    margin-left: 2px;
    margin-right: 2px;
    height: 48px;
    width: 10px;
    background-color: cornflowerblue;
    border-radius: 2px;
}

.goalkeeper.waiting {
    margin-top: -80px;
    animation-name: gk-waiting;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}

@keyframes gk-waiting {
    0% {transform: translateX(0)}
    50% {transform: translateX(30px)}
    75% {transform: translateX(-30px)}
    100% {transform: translateX(0)}
}

@keyframes slideLeft {
    0% {transform: translateX(0)}
    100% {transform: translate(-90px, -20px) rotate(-45deg)}
}

@keyframes slideRight {
    0% {transform: translateX(0)}
    100% {transform: translate(90px, -20px) rotate(45deg)}
}

@keyframes jumpUp {
    0% {transform: translateY(0)}
    100% {transform: translateY(-18px)}
}