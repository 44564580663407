.field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;

    height: calc(100vh - 100px);
    background-color: green;
    /* background:linear-gradient(
        rgba(0, 0, 0, 0.3), 
        rgba(0, 0, 0, 0.3)
      ), url("../field/grass.jpg");
    background-position: center center;
    background-blend-mode:darken; */
}

.goal-field {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ball-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 420px;
    width: 336px;
}

.arrows {
    display: flex;
    margin-bottom: 10px;
}

.arrowBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    width: 72px;
    margin: 20px;

    padding: 10px;
    font-size: 48px;
    font-weight: bold;
    outline: none;
    border: 3px solid #afa;
    border-style: solid;
    border-radius: 50%;
    background-color: #5cb85c;
    color: white;
    cursor: pointer;
    box-shadow: 1px 1px 15px 1px #ddd;
}

.arrowBtnGK {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    width: 72px;
    margin: 20px;

    padding: 10px;
    font-size: 48px;
    font-weight: bold;
    outline: none;
    border: 3px solid #fbb;
    border-style: solid;
    border-radius: 50%;
    background-color: #b85c5c;
    color: white;
    cursor: pointer;
    box-shadow: 1px 1px 15px 1px #ddd;
}

.arrowBtn:hover {
    opacity: .92;
}